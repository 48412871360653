<template>
    <div class="sizeManagement">
         <div class="title-wrap">
            <div class="tag"></div>
            <div class="title">规格管理</div>
        </div>
        <div class="case-wrap">
            <div class="btn-wrap">
                <el-button type="primary" @click="dialogFormVisible = true">添加</el-button>
            </div>
            <el-table :data="tableData" border style="width: 100%" class="table-wrap" v-loading="loading">
                <el-table-column prop="name" label="规格" sortable></el-table-column>
                <!-- <el-table-column prop="width" label="宽度"></el-table-column>
                <el-table-column prop="height" label="高度"></el-table-column>
                <el-table-column prop="thickness" label="厚度"></el-table-column> -->
                <el-table-column prop="sort" label="排序（从小到大）" sortable>
                    <template slot-scope="scope">
                        <el-input-number size="medium" v-model="scope.row.sort" @change="editSort(scope.row)"></el-input-number>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="140" align="center">
                    <template slot-scope="scope">
                        <div class="operation">
                            <div class="del" @click="handeClickEdit(scope.row)">编辑</div>
                            <i class="el-icon-minus"></i>
                            <div class="del" @click="handeClickDel(scope.row)">删除</div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-dialog title="创建规格" :visible.sync="dialogFormVisible" 
            :before-close="handleCloseActivity" class="dialog-wrap"
            :close-on-click-modal="false"
        >
            <div class="bhome-title">
                <div class="item-list">
                    <div class="bt-item">
                        <div class="tag"></div>
                        <div class="title">宽度</div>
                    </div>
                    <el-input type="text" placeholder="请输入宽度"
                        v-model="width"  show-word-limit>
                    </el-input>
                </div>
                <div class="item-list">
                    <div class="bt-item">
                        <div class="tag"></div>
                        <div class="title">高度</div>
                    </div>
                    <el-input type="text" placeholder="请输入高度"
                        v-model="height"  show-word-limit>
                    </el-input>
                </div>
                <div class="item-list">
                    <div class="bt-item">
                        <div class="tag"></div>
                        <div class="title">厚度</div>
                    </div>
                    <el-input type="text" placeholder="请输入厚度"
                        v-model="thickness" show-word-limit>
                    </el-input>
                </div>
                
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="handleCloseActivity">取 消</el-button>
                <el-button :loading="btnLoading" v-if="editId > 0" type="primary" @click="submitEdit">保 存</el-button>
                <el-button :loading="btnLoading" v-else type="primary" @click="submit">创 建</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import common from "@/utils/common.js"
import draggable from "vuedraggable"
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
    components: {
      draggable,
      ElImageViewer
    },
    data () {
        return {
            tableData: [],
            dialogFormVisible: false,
            width: '',
            height: '',
            thickness: '',
            editId: -1,
            loading: false,
            btnLoading: false
        }
    },
    mounted () {
        this.getGoodsSize();
    },
    methods: {
        // 编辑排序
        editSort(row){
            common.connect("/distributorpcv1/goods/setGoodsSizeSortEdit",{id: row.id,sort:row.sort},(res)=>{
                // this.getGoodsSize();
                this.$message.success("保存排序成功");
            });
        },
        // 获取列表数据
        getGoodsSize(){
            this.loading = true;
            common.connect("/distributorpcv1/goods/getGoodsSizeEplist",{},(res)=>{
                this.tableData = res.data;
                this.loading = false;
            });
        },
        // 提交
        submit(){
            if(this.title == ''){
                this.$message.error("请填写系列名称");
                return
            }
            let params = {
                width: this.width,
                height: this.height,
                thickness: this.thickness,
            }
            this.btnLoading = true;
            common.connect("/distributorpcv1/goods/setGoodsSizeEpAdd",params,(res)=>{
                this.getGoodsSize();
                this.dialogFormVisible = false;
                this.width = '';
                this.height = '';
                this.thickness = '';
                this.btnLoading = false;
            });
        },
        // 编辑
        submitEdit(){
            if(this.width == '' || this.height == ''){
                this.$message.error("请填写宽高");
                return
            }
            let params = {
                id: this.editId,
                width: this.width,
                height: this.height,
                thickness: this.thickness,
            }
            this.btnLoading = true;
            common.connect("/distributorpcv1/goods/setGoodsSizeEpEdit",params,(res)=>{
                this.getGoodsSize();
                this.dialogFormVisible = false;
                this.width = '';
                this.height = '';
                this.thickness = '';
                this.editId = -1;
                this.btnLoading = false;
            });
        },
        // 关闭弹窗
        handleCloseActivity(){
            this.dialogFormVisible = false;
            this.width = '';
            this.height = '';
            this.thickness = '';
            this.editId = -1
        },
        // 编辑
        handeClickEdit(row){
            // alert(row.id);
            this.editId = row.id;
            this.dialogFormVisible = true;
            this.width = row.width;
            this.height = row.height;
            this.thickness = row.thickness;
        },
        // 删除
        handeClickDel(row){
            this.$confirm('确认删除该规格?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
          }).then(() => {
            common.connect("/distributorpcv1/goods/setGoodsSizeEpDel",{id: row.id},(res)=>{
                this.getGoodsSize();
                this.$message.success("删除成功")
            });
          }).catch(() => {
          });
        },
    },
}
</script>

<style lang='scss'>
.sizeManagement{
    background: rgb(240, 242, 245);
    padding: 10px 25px;
    height: calc(100vh - 80px);
    overflow-y: auto;
    .title-wrap{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 5px 0;
        .tag{
            width: 4px;
            height: 20px;
            background: #409EFF;
            border-radius: 10px;
        }
        .title{
            font-size: 18px;
            font-weight: bold;
            padding-left: 10px;
        }
    }
    .case-wrap{
        background: #fff;
        padding: 15px 20px;
        margin: 20px 0;
        border-radius: 6px;
        .btn-wrap{
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
        .table-wrap{
            margin: 20px 0;
            .image{
                cursor: pointer;
                width: 100px
            }
            .operation{
                display: flex;
                justify-content: center;
                align-items: center;
                .del{
                    font-size: 14px;
                    color: #409EFF;
                    cursor: pointer;
                    padding: 0 5px;
                }
                .el-icon-minus{
                    transform: rotate(90deg);
                    color: #eee;
                }
            }
        }
    }
    .dialog-wrap{
        .bhome-title{
            margin-top: -20px;
            max-height: 70vh;
            overflow-y: auto;
            padding-bottom: 20px;
            .bt-item{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                padding: 10px 0;
                .tag{
                    width: 3px;
                    height: 16px;
                    background: #409EFF;
                    border-radius: 10px;
                    margin-top: 3px;
                }
                .title{
                    font-size: 16px;
                    color: #000;
                    font-family: SourceHanSansSC-bold;
                    margin-left: 10px;
                }
                .ling{
                    border: 1px solid #333;
                    font-size: 12px;
                    color: #333;
                    border-radius: 8px;
                    padding: 4px 15px;
                    margin-left: 15px;
                    cursor: pointer;
                }
            }
            .tabs-wrap{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                padding-top: 15px;
                .tabs{
                    border: 1px solid #eee;
                    font-size: 14px;
                    padding: 10px 20px;
                    background: #fff;
                    margin-right: 10px;
                    border-radius: 6px;
                    cursor: pointer;
                }
                .active{
                    background: #409EFF;
                    color: #fff;
                }
            }
            .title-alex{
                padding-top: 15px;
            }
            
            .bt-tip{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                padding: 10px 0;
                .el-icon-warning{
                    color: #666;
                    font-size: 14px;
                }
                .tip{
                    font-size: 12px;
                    color: #666;
                    margin-left: 6px;
                }
            }
            .upload-wrap{
                .fileList-box{
                    width: 200px;
                    height: auto;
                    position: relative;
                    .delFile{
                        position: absolute;
                        top: 5px;
                        left: 5px;
                        cursor: pointer;
                        width: 20px;
                        height: 20px;
                        border-radius: 100%;
                        border: 1px #ddd solid;
                        background: rgba(100, 100, 100, 0.5);
                        color: #fff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        z-index: 99;
                    }
                }
                .leright{
                    padding: 15px 0;
                }
            }
        }
        .btn-wrap{
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 10px;
        }
    }
    .el-dialog{
        margin: 0;
        margin-top: 0vh !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        .el-dialog__body{
            padding: 20px;
        }
    }
}
</style>
